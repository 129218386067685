import { template as template_720c8eff7138475e9762d54c8aada9b1 } from "@ember/template-compiler";
const FKLabel = template_720c8eff7138475e9762d54c8aada9b1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
