import { template as template_55d1248e62824601b43fe5f3e909f635 } from "@ember/template-compiler";
const EmptyState = template_55d1248e62824601b43fe5f3e909f635(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
