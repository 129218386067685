import { template as template_1243edfa23e848099b1db5b695f869d7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1243edfa23e848099b1db5b695f869d7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
