import { template as template_118f12ce432d4cbe8bf5ef4b99e317ba } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
const FKCol = template_118f12ce432d4cbe8bf5ef4b99e317ba(`
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCol;
