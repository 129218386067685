import { template as template_117170319074403ebf5d91a63ec90bb8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_117170319074403ebf5d91a63ec90bb8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
