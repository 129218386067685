import { template as template_ab889a8a1021463cbeeeb36e3dce8f1d } from "@ember/template-compiler";
const FKText = template_ab889a8a1021463cbeeeb36e3dce8f1d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
